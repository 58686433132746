<template>
  <b-modal
    v-model="showUser"
    centered
    title="增加机构审核用户"
    size="lg"
    title-class="font-18"
    hide-footer
  >
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">审核范围</label>
      <div class="col-sm-10 p-0 flexList">
        <el-checkbox-group v-model="chooseChecks">
          <el-checkbox
            v-for="(check, i) in checkPowerList"
            :label="check.id"
            :key="i"
            >{{ check.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
    <div class="d-flex check-distri mb-3">
      <label style="width: 10%">审核单位</label>
      <div class="col-sm-10 p-0 flexList">
        <el-checkbox-group v-model="chooseDwChecks">
          <el-checkbox
              v-for="(check, i) in checkDwList"
              :label="check.bshdwdm"
              :key="i"
          >{{ check.bshdwmc }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
    (不选择代表审核所有)
    <yz-search
      :data="search"
      v-model="pageData"
      @seachList="searchClick"
    ></yz-search>
    <div class="table-responsive border mt-3">
      <el-checkbox-group
        v-model="chooseOrgPerson"
        style="max-height: 300px; overflow-y: auto"
      >
        <table class="table light-table table-hover">
          <thead class="thead-light">
            <tr>
              <th style="width: 5%">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  class="mr-1"
                  @change="selectAll"
                >
                </el-checkbox
                >全选
              </th>
              <th style="width: 10%">所属单位</th>
              <th style="width: 7%">登录账号</th>
              <th style="width: 6%">用户姓名</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(obj, index) in tableList" :key="index">
              <td>
                <el-checkbox :label="obj.yhzh" class="mr-1">{{
                  index + 1
                }}</el-checkbox>
              </td>
              <td>{{ obj.dwmc }}</td>
              <td>
                <a
                  href="javascript:;"
                  @click="$yzAdminUserInfo().show(obj.yhzh)"
                  class="font-blue"
                  >{{ obj.yhzh }}</a
                >
              </td>
              <td>{{ obj.xm }}</td>
            </tr>
          </tbody>
        </table>
      </el-checkbox-group>
      <div class="float-right d-flex">
        <el-pagination
            @size-change="handleSizeChange"
            background
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.pageNum"
            :page-size="pageData.pageSize"
            layout="total, sizes,jumper, prev, pager, next"
            :total="pageData.total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="mt-3 text-center">
      <button type="button" class="btn btn-info h30 w-md mr-3" @click="addUser">
        确定添加
      </button>
      <button
        type="button"
        class="btn btn-secondary h30 w-md"
        @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import yzSearch from "@/components/form/yzSearch.vue";
import {getUserManagement} from "@/api/admin/user/institutionManage";
import {addCheckPower} from "@/api/admin/exam/checkPower";
import {slefCheckDwList} from "@/api/admin/exam/checkPower";
export default {
  data() {
    return {
      checkAll: false,
      chooseChecks: [],
      chooseOrgPerson: [],
      isIndeterminate: false,
      search: [],
      pageData: {},
      dict: {},
      tableList: [],
      showUser: false,
      checkDwList: [],
      chooseDwChecks: [],
    };
  },
  methods: {
    show(){
      this.slefCheckDwList()
      this.showUser = true
    },
    hide(){
      this.showUser = false
    },
    selectAll(e) {
      this.chooseOrgPerson = e
        ? this.tableList.map((item) => {
            return item.yhzh;
          })
        : [];
      this.isIndeterminate = e;
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val;
      this.getList();
    },
    searchClick() { //搜索时先将分页的页码归1
      this.pageData.pageNum = 1; //当前页				
      this.getList() //渲染数据的函数
    },
    slefCheckDwList(){
      slefCheckDwList().then(res=>{
        if (res.status){
          this.checkDwList = res.data
        }
      })
    },
    getList() {
      this.pageData.shzt = 1
      getUserManagement("org", this.pageData).then((res) => {
        if (res.status) {
          this.tableList = res.data;
          this.pageData.total = res.total;
          this.pageData.pageNum = res.pageNum;
          this.pageData.pageSize= res.pageSize;
        }
      });
    },
    addUser() {
      let chooseOrgList = this.tableList.filter((item) => {
        delete item["createTime"];
        delete item["modifyTime"];
        item.yhxm = item.xm;
        item.shlb = this.chooseChecks.join(",");
        item.yxshdwm = this.checkDwList.join(",");
        return this.chooseOrgPerson.some((org) => {
          return org == item.yhzh;
        });
      });
      addCheckPower(chooseOrgList).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "新增成功!",
          });
        }
      });
    },
  },
  components: {
    yzSearch,
  },
  mounted() {
    this.getList();
    this.search = [
      {
        title: "姓名/账号/身份证件号",
        bound: "keyword",
        type: "input",
      },
    ];
  }
};
</script>

<style></style>
