<template>
  <div style="margin-bottom: 10px">当前模式：<span style="color: red">{{mode.des}}</span></div>
</template>

<script>
export default {
  name: "change-mode.vue",
  props: {
    ksInfo: {
      default: {}
    }
  },
  data() {
    return {
      modes: [
        {link: "/examinationAuthority", des: "按审核单位审核。即审核单位绑定若干个被审核单位，在审核单位中再设置审核账号，只要属于审核单位的账号都可以审核被审核单位的报名信息，可以为审核账号设置操作权限。适用于公招考试等。", num: 1},
        {link: "/examinationAuthority",des: "按审核单位+区域+专业方式审核。审核单位确定行政区域及所负责审核的专业，审核单位下设置若干账号，账号均可对所划定的区域考生及专业进行审核。适用于资格类考试。", num: 2},
        {link: "/examinationAuthorityRange",des: "按工作任务划分审核。即某一个账号设置多少审核任务，系统自动根据报名序号进行划分。", num: 3},
      ],
      mode: {}
    }
  },
  methods: {
  },
  watch: {
    ksInfo: {
      deep: true,
      handler(e){
        let mode = Number(e.zgjgshshms)
        this.modes.forEach(item=>{
          if(mode == item.num){
            if (this.$route.path != item.link){
              window.location.href = item.link
            } else {
              this.mode = item;
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>